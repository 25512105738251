<script setup>

import { ref } from 'vue'

const anoAtual = ref(new Date().getFullYear())
console.log(anoAtual.value);

const redirectToLinkedIn = () => {
    window.location.href = 'https://www.linkedin.com/company/flashvolve';
};

const redirectToVideoAsk = () => {
    window.location.href = 'https://chat.flashvolve.com/ff1f4cq2g';
};

</script>

<template>
    <div class="landing-container">
        <!-- <div class="landing-nav-bar">
            <header class="landing-header">
                <img src="../assets/logo_bco.png" class="landing-image" />
                <div class="landing-nav">
                    <span class="landing-text">Sobre nós</span>
                    <span class="landing-text01">Soluções</span>
                    <span class="landing-text02">Fale conosco</span>
                </div>
            </header>
        </div> -->
        <div class="landing-hero-section">
            <div class="landing-container01">
                <img alt="image"
                    src="https://res.cloudinary.com/dd9puomgu/image/upload/v1694467062/thqbaxgvkacgtb8kl9cb.webp"
                    class="landing-image01" />
            </div>
        </div>
        <div class="landing-apresentao">
            <img alt="image" src="../assets/logo.png" class="landing-image02" />
            <div class="landing-title">
                <h1 class="landing-text03">
                    Flashvolve - Conectando ao pulso da inovação.
                </h1>
            </div>
            <div class="landing-text-box">
                <span class="landing-text04">
                    <span>PRAZER, SOMOS A FLASHVOLVE.</span>
                    <br />
                    <br />
                    <span class="landing-text-regular">
                        No coração da evolução digital, encontra-se a Flashvolve. Somos uma
                        Enterprise Tech especializada, dedicada a utilizar a inteligência
                        artificial combinada à criatividade para engajar e otimizar os
                        resultados de nossos clientes e colaboradores.
                    </span>
                    <br />
                    <br />
                    <span class="landing-text-regular">
                        Nossa plataforma permite que empresas estabeleçam conexões impactantes
                        por meio de canais de comunicação essenciais, tais como WhatsApp,
                        Instagram, Facebook, Telegram e Slack. Cada interação é concebida com
                        precisão, garantindo comunicação eficaz e engajamento genuíno.
                    </span>
                    <br />
                    <br />
                    <span class="landing-text-regular">
                        Nossa equipe é composta por profissionais dedicados, sempre na
                        vanguarda da inovação. Temos o compromisso constante de refinar e
                        aprimorar as jornadas de comunicação, assegurando que cada interação
                        seja única e impactante. Com a visão clara e o foco em resultados,
                        sabemos que juntos alcançamos patamares mais elevados.
                    </span>
                    <br />
                    <br />
                    <span class="landing-text-regular">
                        A Flashvolve é construída por pessoas e para pessoas. Somos movidos
                        pela paixão de entregar soluções de ponta, garantindo a satisfação e o
                        engajamento de todos os envolvidos. Nosso reconhecimento é o sucesso e
                        o crescimento constante de nossos parceiros e clientes.
                    </span>
                    <br />
                    <br />
                    <span class="landing-text-regular">
                        Nosso time é caracterizado pelo profissionalismo, pela sede de
                        conhecimento e pelo compromisso em fazer a diferença. Estamos
                        presentes no cenário global, com soluções que transcendem fronteiras,
                        preparados para enfrentar os desafios do mercado atual.
                    </span>
                    <br />
                    <br />
                    <span class="landing-text-regular">
                        Em busca de talentos que partilhem da nossa visão e queiram contribuir
                        para a revolução da comunicação, abrimos nossas portas para
                        profissionais de todos os backgrounds e diversidades. Está pronto para
                        fazer parte da nossa história? Aguardamos você.
                    </span>
                </span>
            </div>
            <div class="landing-button-bar">
                <button type="button" @click="redirectToLinkedIn" class="landing-button button">
                    <img alt="image" src="https://api.iconify.design/entypo-social/linkedin-with-circle.svg"
                        class="landing-image03" />
                    <span class="landing-text24">LinkedIn</span>
                </button>
            </div>
            <div class="landing-images">
                <!-- <img
            alt="image"
            src="/sonhando-jovem-empresaria-africana-600w.png"
            class="landing-image05"
          />
          <img
            alt="image"
            src="/jovens-alegres-colegas-dentro-de-coworking-600w.png"
            class="landing-image06"
          /> -->
            </div>
        </div>
        <div class="landing-cards">
            <div class="landing-container02">
                <div class="landing-title1">
                    <h1>Benefícios</h1>
                </div>
                <div class="landing-show">
                    <div class="landing-card1">
                        <div class="landing-icon">
                            <img alt="image" src="https://api.iconify.design/mdi/image-edit-outline.svg?color=%23fccf00"
                                class="landing-image07" />
                        </div>
                        <div class="landing-container03">
                            <h2 class="landing-heading">
                                <span>Maximize seus resultados</span>
                                <br />
                            </h2>
                            <span class="landing-text29">
                                Somos uma Enterprise Tech que utiliza inteligência artificial e
                                criativa para engajar e maximizar os resultados dos seus clientes
                                e colaboradores.
                            </span>
                        </div>
                    </div>
                    <div class="landing-card3">
                        <div class="landing-icon1">
                            <img alt="image" src="https://api.iconify.design/mdi/image-edit-outline.svg?color=%23fccf00"
                                class="landing-image08" />
                        </div>
                        <div class="landing-container04">
                            <h2 class="landing-heading1">
                                <span>Maximize seus resultados</span>
                                <br />
                            </h2>
                            <span class="landing-text32">
                                Somos uma Enterprise Tech que utiliza inteligência artificial e
                                criativa para engajar e maximizar os resultados dos seus clientes
                                e colaboradores.
                            </span>
                        </div>
                    </div>
                    <div class="landing-card31">
                        <div class="landing-icon2">
                            <img alt="image" src="https://api.iconify.design/mdi/image-edit-outline.svg?color=%23fccf00"
                                class="landing-image09" />
                        </div>
                        <div class="landing-container05">
                            <h2 class="landing-heading2">
                                <span>Maximize seus resultados</span>
                                <br />
                            </h2>
                            <span class="landing-text35">
                                Somos uma Enterprise Tech que utiliza inteligência artificial e
                                criativa para engajar e maximizar os resultados dos seus clientes
                                e colaboradores.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="landing-video">
            <div class="landing-content">
                <h1>Vídeo de apresentação</h1>
                <video src="https://youtu.be/GWV6feHUZE4?si=C_YyXKCyqw6bb8SQ" preload="auto" autoplay
                    class="landing-video1"></video>
            </div>
        </div>
        <div class="landing-flashers">
            <div class="landing-container06">
                <div class="landing-title2">
                    <h1>Somos Flashers! ⚡</h1>
                </div>
                <div class="landing-show1">
                    <div class="landing-card11">
                        <div class="landing-icon3">
                            <!-- <img
                  alt="image"
                  src="/le%C3%AC%C2%81o-1500h.png"
                  class="landing-image10"
                /> -->
                        </div>
                        <div class="landing-container07">
                            <h2 class="landing-heading3">Leandro Alvarenga</h2>
                            <span class="landing-text38">CEO</span>
                            <span class="landing-text39">
                                Empreendedor experiente por 16 anos, líder na Prime Arte,
                                produtora de filmes para grandes corporações. CEO da Flashvolve,
                                empresa de tecnologia empresarial que usa IA e criatividade para
                                potencializar resultados de clientes e colaboradores. Também é
                                sócio fundador da Flashvolve.
                            </span>
                        </div>
                    </div>
                    <div class="landing-card2">
                        <div class="landing-icon4">
                            <!-- <img alt="image" src="/david-200h.png" class="landing-image11" /> -->
                        </div>
                        <div class="landing-container08">
                            <h2 class="landing-heading4">David Ledson</h2>
                            <span class="landing-text40">CEO</span>
                            <span class="landing-text41">
                                Com mais de 20 anos de experiência em liderança, desenvolvimento
                                humano e organizacional, planejamento estratégico,
                                empreendedorismo e inovação, é sócio da Sólides, investidor anjo e
                                mentor em acelerações. Mestrado em Administração pela UFMG e
                                treinamento em liderança e inovação.
                            </span>
                        </div>
                    </div>
                    <div class="landing-card32">
                        <div class="landing-icon5">
                            <!-- <img
                  alt="image"
                  src="/whatsapp%20image%202022-08-23%20at%2013.54.29-200h.jpeg"
                  class="landing-image12"
                /> -->
                        </div>
                        <div class="landing-container09">
                            <h2 class="landing-heading5">
                                <span>Yago Fernandes</span>
                                <br />
                            </h2>
                            <span class="landing-text44">CTO</span>
                            <span class="landing-text45">
                                Entusiasta e amante da tecnologia, CTO formado na IFTL e CTO na
                                Flashvolve. Focado em produto e gestão de pessoas.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="landing-fale-conosco">
            <div class="landing-content1">
                <img alt="image" src="../assets/grafismo-logo_amarelo.png" class="landing-image13" />
                <h1 class="landing-text46">Quer fazer parte do nosso time?</h1>
                <span class="landing-text47">
                    Acesse nosso formulário e cadastre-se em nosso banco de talentos.
                </span>
                <button type="button" @click="redirectToVideoAsk" class="landing-button2">
                    <span>
                        <span>Acessar Formulário</span>
                        <br />
                    </span>
                </button>
            </div>
        </div>
        <div class="landing-footer">
            <div class="landing-content2">
                <div class="landing-container10">
                    <div class="landing-flashvolve">
                        <img alt="image" src="../assets/logo_bco.png" class="landing-logo" />
                        <div class="landing-instagram">
                            <img alt="image" src="https://api.iconify.design/grommet-icons/instagram.svg?color=%23fccf00"
                                class="landing-image14" />
                            <span>Instagram</span>
                        </div>
                        <div class="landing-linked-in">
                            <img alt="image" src="https://api.iconify.design/pajamas/linkedin.svg?color=%23fccf00"
                                class="landing-image15" />
                            <span>LinkedIn</span>
                        </div>
                    </div>
                    <div class="landing-solues">
                        <ul class="landing-ul list">
                            <li class="landing-li list-item">
                                <span class="landing-text53">Soluções</span>
                            </li>
                            <li class="landing-li01 list-item">
                                <span href="#growth" class="landing-text54">Growth</span>
                            </li>
                            <li class="landing-li02 list-item">
                                <span href="https://flashvolve.com/#solucoes">
                                    Automação Criativa
                                </span>
                            </li>
                            <li class="landing-li03 list-item">
                                <span href="#predicao" class="landing-text56">Predição</span>
                            </li>
                            <li class="landing-li04 list-item">
                                <span href="#comunicacao" class="landing-text57">
                                    Comunicação
                                </span>
                            </li>
                            <li class="landing-li05 list-item">
                                <span href="#saude-seguranca" class="landing-text58">
                                    Saúde e Segurança
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="landing-sobre-ns">
                        <ul class="landing-ul1 list">
                            <li class="landing-li06 list-item">
                                <span href="https://flashvolve.com/#solucoes">Sobre nós</span>
                            </li>
                            <li class="landing-li07 list-item">
                                <span href="https://flashvolve.com/#sobre-nos" class="landing-text60">
                                    Clientes
                                </span>
                            </li>
                            <li class="landing-li08 list-item">
                                <span href="https://flashvolve.com/#clientes" class="landing-text61">
                                    Benefícios
                                </span>
                            </li>
                            <li class="landing-li09 list-item">
                                <span href="https://flashvolve.com/#faleconosco" class="landing-text62">
                                    Fale conosco
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="landing-rodap">
                    <p class="landing-text63">
                        <span>
                            © {{ anoAtual }} Flashvolve todos os direitos reservados.
                            <span></span>
                        </span>
                        <span><span></span></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<style scoped>
.button {
    padding: 10px 15px;
    border-radius: 4px;
}

.landing-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.landing-nav-bar {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #000000;
}

.landing-header {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.landing-image {
    height: 2rem;
}

.landing-nav {
    display: none;
    color: #ffffff;
    font-family: "Fivo Sans Modern Medium";
}

.landing-text {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text01 {
    font-family: "Fivo Sans Modern Medium";
    margin-left: 32px;
}

.landing-text02 {
    font-family: "Fivo Sans Modern Medium";
    margin-left: 32px;
}

.landing-hero-section {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.landing-container01 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #fccf00;
}

.landing-image01 {
    width: 100%;
    align-self: flex-end;
    object-fit: cover;
}

.landing-apresentao {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
    justify-content: center;
}

.landing-image02 {
    width: 20%;
    margin-top: -7%;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 50%;
    padding-right: 10px;
    padding-bottom: 10px;
}

.landing-title {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
}

.landing-text03 {
    padding: 20px;
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
}

.landing-text-box {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 40px;
    font-size: 18px;
    max-width: 1200px;
    align-items: flex-start;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
}

.landing-text04 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text-regular {
    font-family: "Fivo Sans Modern Regular";
}

.landing-button-bar {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1200px;
    align-items: flex-start;
    padding-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-button {
    gap: 10px;
    display: flex;
    transition: 0.3s;
    align-items: center;
    font-family: "Fivo Sans Modern Medium";
    border-color: rgb(252, 207, 0);
    margin-right: 10px;
    border-radius: 20px;
    flex-direction: row;
    background-color: rgb(252, 207, 0);
}

.landing-button:hover {
    border-width: 2px;
    border-color: #000000;
    cursor: pointer;
}

.landing-image03 {
    width: 19px;
    height: auto;
    object-fit: cover;
}

.landing-text24 {
    text-align: left;
}

.landing-button1 {
    gap: 10px;
    display: flex;
    transition: 0.3s;
    font-family: "Fivo Sans Modern Medium";
    border-color: rgb(252, 207, 0);
    margin-right: 10px;
    border-radius: 20px;
    flex-direction: row;
    background-color: rgb(252, 207, 0);
}

.landing-button1:hover {
    border-width: 2px;
    border-color: #000000;
    cursor: pointer;
}

.landing-image04 {
    width: 20px;
    height: auto;
    object-fit: cover;
}

.landing-images {
    gap: 20px;
    width: 100%;
    height: auto;
    display: none;
    padding: 40px;
    max-width: 1200px;
    justify-content: center;
}

.landing-image05 {
    width: 45%;
    max-width: 550px;
}

.landing-image06 {
    width: 45%;
    max-width: 550px;
}

.landing-cards {
    gap: 20px;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.landing-container02 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 40px;
}

.landing-title1 {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    padding: 40px;
    max-width: 1200px;
    align-items: flex-start;
}

.landing-show {
    gap: 20px;
    display: flex;
    max-width: 1200;
    align-items: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: space-between;
}

.landing-card1 {
    width: 33%;
    height: fit-content;
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-icon {
    width: 120px;
    height: 120px;
    display: flex;
    position: absolute;
    margin-top: -55;
    align-items: center;
    border-color: #fccf00;
    border-width: 5px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #000000;
}

.landing-image07 {
    width: 50px;
    height: 50px;
}

.landing-container03 {
    height: 80%;
    display: flex;
    min-width: 220px;
    margin-top: 0px;
    align-items: center;
    padding-top: 70px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f3f3f3;
}

.landing-heading {
    width: auto;
    margin-top: 10px;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    margin-left: 0px;
    padding-top: 20x;
    margin-right: 0px;
    padding-left: 40px;
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-text29 {
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
    padding-top: 20px;
    padding-left: 40;
    padding-right: 40;
    padding-bottom: 30;
}

.landing-card3 {
    width: 33%;
    height: fit-content;
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-icon1 {
    width: 120px;
    height: 120px;
    display: flex;
    position: absolute;
    margin-top: -55;
    align-items: center;
    border-color: #fccf00;
    border-width: 5px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #000000;
}

.landing-image08 {
    width: 50px;
    height: 50px;
}

.landing-container04 {
    height: 80%;
    display: flex;
    min-width: 220px;
    margin-top: 0px;
    align-items: center;
    padding-top: 70px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f3f3f3;
}

.landing-heading1 {
    width: auto;
    margin-top: 10px;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    margin-left: 0px;
    padding-top: 20x;
    margin-right: 0px;
    padding-left: 40px;
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-text32 {
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
    padding-top: 20px;
    padding-left: 40;
    padding-right: 40;
    padding-bottom: 30;
}

.landing-card31 {
    width: 33%;
    height: fit-content;
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-icon2 {
    width: 120px;
    height: 120px;
    display: flex;
    position: absolute;
    margin-top: -55;
    align-items: center;
    border-color: #fccf00;
    border-width: 5px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #000000;
}

.landing-image09 {
    width: 50px;
    height: 50px;
}

.landing-container05 {
    height: 80%;
    display: flex;
    min-width: 220px;
    margin-top: 0px;
    align-items: center;
    padding-top: 70px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f3f3f3;
}

.landing-heading2 {
    width: auto;
    margin-top: 10px;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    margin-left: 0px;
    padding-top: 20x;
    margin-right: 0px;
    padding-left: 40px;
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-text35 {
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
    padding-top: 20px;
    padding-left: 40;
    padding-right: 40;
    padding-bottom: 30;
}

.landing-video {
    flex: 0 0 auto;
    width: 100%;
    display: none;
    align-items: flex-start;
    padding-top: 30px;
    justify-content: center;
}

.landing-content {
    gap: 20px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: flex-start;
    padding-left: 40px;
    border-radius: 12px;
    padding-right: 40px;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-video1 {
    width: 100%;
    height: auto;
    display: none;
    border-radius: 12px;
    background-color: #000000;
}

.landing-div {
    display: contents;
}

.landing-flashers {
    gap: 20px;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 40px;
    justify-content: center;
}

.landing-container06 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 1200px;
    margin-top: 20px;
    align-items: flex-start;
    flex-direction: column;
}

.landing-title2 {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    padding: 40px;
    max-width: 1200px;
    align-items: flex-start;
}

.landing-show1 {
    gap: 20px;
    display: flex;
    align-items: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: row;
    justify-content: space-between;
}

.landing-card11 {
    width: 33%;
    height: fit-content;
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-icon3 {
    width: 120px;
    height: 120px;
    display: flex;
    position: absolute;
    margin-top: -55;
    align-items: center;
    border-color: #fccf00;
    border-width: 5px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #000000;
}

.landing-image10 {
    width: 112px;
    height: 112PX;
    border-radius: 50%;
}

.landing-container07 {
    height: 80%;
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-top: 70px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f3f3f3;
}

.landing-heading3 {
    width: auto;
    margin-top: 10px;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    margin-left: 0px;
    padding-top: 20x;
    margin-right: 0px;
    padding-left: 40px;
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-text38 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text39 {
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
    padding-top: 20px;
    padding-left: 40;
    padding-right: 40;
    padding-bottom: 30;
}

.landing-card2 {
    width: 33%;
    height: fit-content;
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-icon4 {
    width: 120px;
    height: 120px;
    display: flex;
    position: absolute;
    margin-top: -55;
    align-items: center;
    border-color: #fccf00;
    border-width: 5px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #000000;
}

.landing-image11 {
    width: 112px;
    height: 112px;
    border-radius: 50%;
}

.landing-container08 {
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-top: 70px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f3f3f3;
}

.landing-heading4 {
    width: auto;
    margin-top: 10px;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    margin-left: 0px;
    padding-top: 20x;
    margin-right: 0px;
    padding-left: 40px;
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-text40 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text41 {
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
    padding-top: 20px;
    padding-left: 40;
    padding-right: 40;
    padding-bottom: 30;
}

.landing-card32 {
    width: 33%;
    height: fit-content;
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.landing-icon5 {
    width: 120px;
    height: 120px;
    display: flex;
    position: absolute;
    margin-top: -55;
    align-items: center;
    border-color: #fccf00;
    border-width: 5px;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    background-color: #000000;
}

.landing-image12 {
    width: 112px;
    height: 112px;
    border-radius: 50%;
}

.landing-container09 {
    height: 80%;
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-top: 70px;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f3f3f3;
}

.landing-heading5 {
    width: auto;
    margin-top: 10px;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    margin-left: 0px;
    padding-top: 20x;
    margin-right: 0px;
    padding-left: 40px;
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
}

.landing-text44 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text45 {
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
    padding-top: 20px;
    padding-left: 40;
    padding-right: 40;
    padding-bottom: 30;
}

.landing-fale-conosco {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 40px;
    align-items: flex-start;
    justify-content: center;
}

.landing-content1 {
    width: 100%;
    height: auto;
    display: flex;
    max-width: 600px;
    align-items: center;
    padding-top: 40px;
    flex-direction: column;
    padding-bottom: 70px;
    justify-content: center;
}

.landing-image13 {
    width: 150px;
    object-fit: cover;
}

.landing-text46 {
    font-size: 30px;
    align-self: center;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
}

.landing-text47 {
    font-size: 25px;
    align-self: center;
    text-align: center;
    padding-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.landing-button2 {
    align-self: center;
    text-align: center;
    font-family: "Fivo Sans Modern Bold";
    padding-top: 15px;
    border-width: 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 15px;
    background-color: #ffcf00;
}

.landing-button2:hover {
    border-width: 2px;
    border-color: #000000;
    cursor: pointer;
}

.landing-footer {
    color: #fff;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #000;
}

.landing-content2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1215px;
    align-self: center;
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
}

.landing-container10 {
    gap: 40px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    /* padding-left: 40px; */
    justify-content: center;
}

.landing-flashvolve {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}

.landing-logo {
    width: 100%;
    height: auto;
    max-width: 300px;
    object-fit: contain;
}

.landing-instagram {
    gap: 10px;
    flex: 0 0 auto;
    width: 100%;
    display: none;
    margin-top: 20px;
    align-items: center;
    flex-direction: row;
}

.landing-image14 {
    width: 25px;
}

.landing-linked-in {
    gap: 10px;
    flex: 0 0 auto;
    width: 100%;
    display: none;
    margin-top: 5px;
    align-items: center;
    flex-direction: row;
}

.landing-image15 {
    width: 25px;
}

.landing-solues {
    width: 25%;
    height: auto;
    display: none;
    align-items: flex-start;
    padding-left: 0px;
    flex-direction: column;
}

.landing-ul {
    height: auto;
    align-self: flex-start;
    margin-top: 0px;
    align-items: flex-start;
    padding-left: 0;
    margin-bottom: 0px;
}

.landing-li {
    font-family: "Fivo Sans Modern Bold";
}

.landing-text53 {
    font-style: normal;
    font-weight: 700;
}

.landing-li01 {
    margin-top: 5px;
    font-family: "Fivo Sans Modern Medium";
}

.landing-text54 {
    text-decoration: none;
}

.landing-li02 {
    font-family: "Fivo Sans Modern Medium";
    text-decoration: none;
}

.landing-li03 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text56 {
    text-decoration: none;
}

.landing-li04 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text57 {
    text-decoration: none;
}

.landing-li05 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text58 {
    text-decoration: none;
}

.landing-sobre-ns {
    width: 25%;
    height: auto;
    display: none;
    align-items: flex-start;
    flex-direction: column;
}

.landing-ul1 {
    height: auto;
    margin-top: 0px;
    padding-left: 0;
    margin-bottom: 0px;
}

.landing-li06 {
    font-family: "Fivo Sans Modern Bold";
}

.landing-li07 {
    margin-top: 5px;
    font-family: "Fivo Sans Modern Medium";
}

.landing-text60 {
    text-decoration: none;
}

.landing-li08 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text61 {
    text-decoration: none;
}

.landing-li09 {
    font-family: "Fivo Sans Modern Medium";
}

.landing-text62 {
    text-decoration: none;
}

.landing-rodap {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: 50px;
    align-items: flex-start;
    justify-content: center;
}

.landing-text63 {
    padding: 40px;
    font-size: 16px;
    text-align: center;
    font-family: "Fivo Sans Modern Medium";
}

@media(max-width: 1200px) {

    .landing-text46 {
        font-size: 30px;
    }

    .landing-text47 {
        font-size: 25px;
    }
}

@media(max-width: 957px) {
    .landing-images {
        align-items: center;
        flex-direction: column;
    }

    .landing-image05 {
        width: 80%;
    }

    .landing-image06 {
        width: 80%;
    }

    .landing-cards {
        display: none;
    }

    .landing-show {
        padding-left: 80px;
        padding-right: 80px;
        flex-direction: column;
    }

    .landing-card1 {
        width: 100%;
    }

    .landing-card3 {
        width: 100%;
    }

    .landing-card31 {
        width: 100%;
    }

    .landing-video {
        display: none;
    }

    .landing-container06 {
        flex-direction: column;
    }

    .landing-show1 {
        gap: 50px;
        padding-left: 80px;
        padding-right: 80px;
        flex-direction: column;
    }

    .landing-card11 {
        width: 100%;
    }

    .landing-text39 {
        align-self: center;
        text-align: center;
        font-family: "Fivo Sans Modern Medium";
    }

    .landing-card2 {
        width: 100%;
        height: fit-content;
    }

    .landing-text41 {
        align-self: flex-end;
        align-items: flex-end;
        font-family: "Fivo Sans Modern Medium";
        flex-direction: column;
        justify-content: flex-end;
    }

    .landing-card32 {
        gap: 50px;
        width: 100%;
    }

    .landing-text44 {
        font-family: "Fivo Sans Modern Medium";
    }

    .landing-text45 {
        font-family: "Fivo Sans Modern Medium";
    }
}

@media(max-width: 767px) {
    .landing-header {
        padding-left: 32px;
        padding-right: 32px;
    }

    .landing-nav {
        display: none;
    }

    .landing-text {
        margin-bottom: 16px;
    }

    .landing-text01 {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .landing-text02 {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .landing-image02 {
        width: 22%;
        margin-top: -10%;
    }

    .landing-image05 {
        width: 100%;
    }

    .landing-image06 {
        width: 100%;
    }

    .landing-show {
        gap: 80px;
        flex-direction: column;
    }

    .landing-container06 {
        flex-direction: column;
    }

    .landing-show1 {
        gap: 80px;
        align-items: center;
        padding-left: 80px;
        padding-right: 80px;
        flex-direction: column;
        justify-content: center;
    }

    .landing-card11 {
        max-width: 100%;
    }

    .landing-content2 {
        padding: 0px 20px;
    }
}

@media(max-width: 479px) {
    .landing-header {
        padding: 16px;
    }

    .landing-hero-section {
        background-color: #FCCF00;
    }

    .landing-container01 {
        background-color: #FCCF00;
    }

    .landing-image01 {
        left: 0;
        height: 215px;
    }

    .landing-image02 {
        width: 30%;
        margin-top: -13%;
        margin-bottom: 5%;
    }

    .landing-text03 {
        font-family: "Fivo Sans Modern Medium";
        font-size: 1.75rem;
        padding: 0px 20px;
    }

    .landing-text04 {
        font-family: "Fivo Sans Modern Medium";
    }


    .landing-image05 {
        min-width: 400px;
    }

    .landing-image06 {
        min-width: 400px;
    }

    .landing-show {
        gap: 50px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .landing-show1 {
        gap: 50px;
        flex-direction: column;
    }

    .landing-text39 {
        font-family: "Fivo Sans Modern Medium";
    }

    .landing-text41 {
        font-family: "Fivo Sans Modern Medium";
    }

    .landing-content2 {
        flex-wrap: wrap;
        padding-left: 10px;
        padding-right: 10px;
    }

    .landing-container10 {
        flex-direction: column-reverse;
    }

    .landing-solues {
        width: 100%;
    }

    .landing-sobre-ns {
        width: 100%;
    }
}
</style>
  