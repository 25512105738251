import { createRouter, createWebHistory } from 'vue-router'

import NotFound from './views/not-found'
import TrabalheConosco from './views/JoinUs'
const routes = [
    {
      path: '/:pathMatch(.*)*',
      name: '404 - Not Found',
      component: NotFound,
    },
    {
      name: 'trabalhe-conosco',
      path: '/',
      component: TrabalheConosco,
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;
