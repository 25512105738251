<template>
  <router-view />
</template>

<script>

export default {
  data() {
    return { result: null, test: null, itemExist: false, isFinished: false };
  },
}
</script>


<style>
@font-face {
  font-family: 'Fivo Sans Modern Bold';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Bold_i6ritq.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Regular';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Regular_dxkwd3.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern ExtBlack';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-ExtBlack_bo75ny.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Medium';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Medium_yujzzk.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Heavy';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Heavy_gchhqo.otf);
}

* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
h1{
  margin: 0px
}
input {
  border: none;
  padding: 0px;
}

input:focus-visible {
  border: none;
  outline: none;
}

#app {
  padding: 0px;
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
}

body {
  font-family: 'Fivo Sans Modern Regular';
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
}
</style>
